<template>
  <b-overlay variant="white" rounded="sm" opacity="0.9" :show="false">
    <!-- DETAILS -->
    <promotion-details-card />
    <!-- ACCOMMODATIONS -->
    <promotion-accommodations-card :accommodations="promotionAccommodations" />
    <!-- BOOKINGS -->
    <promotion-bookings-card :bookings="promotionBookings" />
    <!-- DELETE -->
    <b-card title="Danger zone">
      <b-row>
        <b-col cols="12" class="mb-1">
          <b-button
            size="xl"
            variant="danger"
            class="shake"
            @click="confirmDeletePromotion"
          >
            Elimina la promoció
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BButton } from "bootstrap-vue";
import { notifyError, notifySuccess } from "@/utils/methods";
import PromotionDetailsCard from "@/views/promotions/promotion/components/PromotionDetailsCard.vue";
import PromotionAccommodationsCard from "@/views/promotions/promotion/components/PromotionAccommodationsCard.vue";
import PromotionBookingsCard from "@/views/promotions/promotion/components/PromotionBookingsCard.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    PromotionDetailsCard,
    PromotionAccommodationsCard,
    PromotionBookingsCard,
  },
  created() {
    this.fetchPromotion();
    this.fetchAccommodations();
  },
  computed: {
    promotion() {
      return this.$store.getters["promotion/promotion"];
    },
    promotionAccommodations() {
      return this.promotion?.accommodations || [];
    },
    promotionBookings() {
      return this.promotion?.bookings || [];
    },
  },
  beforeDestroy() {
    this.$store.dispatch("promotion/reset");
  },
  methods: {
    fetchPromotion() {
      this.$store.dispatch("app/setLoading", true);
      const { promotionUuid } = this.$route.params;

      this.$store
        .dispatch("promotion/fetchPromotion", promotionUuid)
        .catch(() => {
          notifyError(
            this.$t("errors.fetchPromotion.title"),
            this.$t("errors.fetchPromotion.description")
          );
          this.$router.push({ name: "foravila-promotions-list" });
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    fetchAccommodations() {
      this.$store
        .dispatch("accommodations/fetchAccommodations", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchAccommodations.title"),
            this.$t("errors.fetchAccommodations.description")
          )
        );
    },
    confirmDeletePromotion() {
      this.$swal({
        title: "Eliminar promoció",
        text: "Estàs a punt d'eliminar aquesta promoció, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deletePromotion();
      });
    },
    deletePromotion() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("promotion/deletePromotion", this.promotion.uuid)
        .then(() => {
          notifySuccess(
            "Promoció eliminada",
            "La promoció ha estat eliminada correctament."
          );
          this.$router.push({ name: "foravila-promotions-list" });
        })
        .catch(() => {
          notifyError(
            "Promoció no eliminada",
            "Hi ha hagut un error al eliminar la promoció."
          );
          // TODO: log error in Sentry
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
