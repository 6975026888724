<template>
  <b-card :title="title">
    <!-- MAIN INFO -->
    <b-row>
      <!-- CATEGORY -->
      <b-col cols="12" sm="6" lg="3">
        <b-form-group>
          <h6 class="mb-25">Categoria</h6>
          <div id="category">
            {{ category }}
          </div>
        </b-form-group>
      </b-col>

      <!-- CODE -->
      <b-col v-if="showCode" cols="12" sm="6" lg="3">
        <b-form-group>
          <h6 class="mb-25">Codi</h6>
          <div id="code">
            {{ code }}
          </div>
        </b-form-group>
      </b-col>

      <!-- TYPE -->
      <b-col cols="12" sm="6" lg="3">
        <b-form-group>
          <h6 class="mb-25">Tipus</h6>
          <div id="type">
            {{ type }}
          </div>
        </b-form-group>
      </b-col>

      <!-- SCOPE -->
      <b-col cols="12" sm="6" lg="3">
        <b-form-group>
          <h6 class="mb-25">Abast</h6>
          <div id="scope">
            {{ scope }}
          </div>
        </b-form-group>
      </b-col>

      <!-- VALUE -->
      <b-col cols="12" sm="6" lg="3">
        <b-form-group>
          <h6 class="mb-25">Valor</h6>
          <div id="value">
            {{ value }}
          </div>
        </b-form-group>
      </b-col>

      <!-- MIN STAY -->
      <b-col cols="12" sm="6" lg="3">
        <b-form-group>
          <h6 class="mb-25">Estada mínima</h6>
          <div id="minStay">
            {{ minStay }}
          </div>
        </b-form-group>
      </b-col>

      <!-- MAX BOOKINGS -->
      <b-col cols="12" sm="6" lg="3">
        <b-form-group>
          <h6 class="mb-25">Màxim d'usos</h6>
          <div id="maxBookings">
            {{ maxBookings }}
          </div>
        </b-form-group>
      </b-col>

      <!-- ADVANCED BOOKING DAYS -->
      <b-col v-if="showAdvancedBookingDays" cols="12" sm="6" lg="3">
        <b-form-group>
          <h6 class="mb-25">
            {{ advancedBookingDaysInputLabel }}
          </h6>
          <div id="advancedBookingDays">
            {{ advancedBookingDays }}
          </div>
        </b-form-group>
      </b-col>

      <!-- CHECKIN DAYS -->
      <b-col cols="12">
        <b-form-group>
          <h6 class="mb-25">Dies d'entrada</h6>
          <div id="checkinDays">
            {{ checkinDays }}
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- BOOKING START DATE -->
      <b-col cols="12" lg="6" xl="3">
        <b-form-group>
          <h6 class="mb-25">Data d'inici del període de reserva</h6>
          <div id="bookingStartDate">
            {{ bookingStartDate }}
          </div>
        </b-form-group>
      </b-col>

      <!-- BOOKING END DATE -->
      <b-col cols="12" lg="6" xl="3">
        <b-form-group>
          <h6 class="mb-25">Data de fi del període de reserva</h6>
          <div id="bookingEndDate">
            {{ bookingEndDate }}
          </div>
        </b-form-group>
      </b-col>

      <!-- STAY START DATE -->
      <b-col cols="12" lg="6" xl="3">
        <b-form-group>
          <h6 class="mb-25">Data d'inici de l'estada</h6>
          <div id="stayStartDate">
            {{ stayStartDate }}
          </div>
        </b-form-group>
      </b-col>

      <!-- STAY END DATE -->
      <b-col cols="12" lg="6" xl="3">
        <b-form-group>
          <h6 class="mb-25">Data de fi de l'estada</h6>
          <div id="stayEndDate">
            {{ stayEndDate }}
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup } from "bootstrap-vue";
import {
  getBookingsText,
  getDaysText,
  getPromotionCategoryName,
  getPromotionScopeName,
  getPromotionTypeName,
  getPromotionValueText,
} from "@/utils/methods";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
  },
  computed: {
    promotion() {
      return this.$store.getters["promotion/promotion"];
    },
    title() {
      return this.promotion?.title || this.$t("Promoció");
    },
    category() {
      return (
        getPromotionCategoryName(this.promotion?.category, this.$i18n.locale) ||
        this.$t("No disponible")
      );
    },
    code() {
      return this.promotion?.code || this.$t("No disponible");
    },
    showCode() {
      return this.promotion?.category === "PROMO_CODE";
    },
    type() {
      return (
        getPromotionTypeName(this.promotion?.type, this.$i18n.locale) ||
        this.$t("No disponible")
      );
    },
    scope() {
      return (
        getPromotionScopeName(this.promotion?.scope, this.$i18n.locale) ||
        this.$t("No disponible")
      );
    },
    value() {
      return (
        getPromotionValueText(this.promotion, this.$i18n.locale) ||
        this.$t("No disponible")
      );
    },
    minStay() {
      return (
        getDaysText(this.promotion?.minStay, this.$i18n.locale) ||
        this.$t("No disponible")
      );
    },
    maxBookings() {
      return (
        getBookingsText(this.promotion?.maxBookings, this.$i18n.locale) ||
        this.$t("No disponible")
      );
    },
    advancedBookingDays() {
      return (
        getDaysText(this.promotion?.advancedBookingDays, this.$i18n.locale) ||
        this.$t("No disponible")
      );
    },
    showAdvancedBookingDays() {
      if (!this.promotion) return false;
      return !["STANDARD", "PROMO_CODE"].includes(this.promotion?.category);
    },
    advancedBookingDaysInputLabel() {
      if (!this.promotion?.category) return null;
      if (this.promotion.category === "LAST_MINUTE")
        return "Antel·lació màxima";
      if (this.promotion.category === "EARLY_BOOKING")
        return "Antel·lació mínima";
      return null;
    },
    checkinDays() {
      if (!this.promotion?.checkinDays?.length) return this.$t("No disponible");
      if (this.promotion.checkinDays.length === 7) return this.$t("Lliure");
      return this.promotion.checkinDays
        .map((day) => {
          return day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
        })
        .join(", ");
    },
    bookingStartDate() {
      return (
        formatDateStringToDate(
          this.promotion?.bookingStartDate,
          this.$i18n.locale
        ) || this.$t("No disponible")
      );
    },
    bookingEndDate() {
      return (
        formatDateStringToDate(
          this.promotion?.bookingEndDate,
          this.$i18n.locale
        ) || this.$t("No disponible")
      );
    },
    stayStartDate() {
      return (
        formatDateStringToDate(
          this.promotion?.stayStartDate,
          this.$i18n.locale
        ) || this.$t("No disponible")
      );
    },
    stayEndDate() {
      return (
        formatDateStringToDate(
          this.promotion?.stayEndDate,
          this.$i18n.locale
        ) || this.$t("No disponible")
      );
    },
  },
};
</script>
