<template>
  <b-card title="Reserves">
    <b-static-table
      v-if="mappedBookings.length"
      :items="mappedBookings"
      :fields="fields"
      show-search
      @item-click="onBookingClicked"
    />

    <!-- NO ITEMS ALERT -->
    <b-alert class="mb-2" variant="warning" :show="mappedBookings.length === 0">
      <div class="alert-body">
        No hi ha cap reserva que hagi utilitzat aquesta promoció
      </div>
    </b-alert>
  </b-card>
</template>

<script>
import { BCard, BAlert } from "bootstrap-vue";
import BStaticTable from "@foravila-core/components/b-static-table/BStaticTable.vue";
import { formatDateStringToDate } from "@/utils/formatters";
import { getBookingStatusName } from "@/utils/methods";

export default {
  components: {
    BCard,
    BAlert,
    BStaticTable,
  },
  props: {
    bookings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: "localizator", label: this.$t("Localitzador"), sortable: true },
        { key: "accommodation", label: this.$t("Allotjament"), sortable: true },
        { key: "client", label: this.$t("Client"), sortable: true },
        { key: "checkin", label: this.$t("Entrada"), sortable: true },
        { key: "checkout", label: this.$t("Sortida"), sortable: true },
        { key: "status", label: this.$t("Estat"), sortable: true },
      ],
    };
  },
  computed: {
    mappedBookings() {
      if (!this.bookings?.length) return [];
      return this.bookings.map((booking) => ({
        uuid: booking.uuid,
        localizator: booking.localizator,
        accommodation: booking.accommodation?.name || this.$t("No disponible"),
        client: booking.client?.fullName || this.$t("No disponible"),
        checkin:
          formatDateStringToDate(booking.checkin) || this.$t("No disponible"),
        checkout:
          formatDateStringToDate(booking.checkout) || this.$t("No disponible"),
        status: getBookingStatusName(booking.status, this.$i18n.locale),
      }));
    },
  },
  methods: {
    onBookingClicked(uuid) {
      const foundBoooking = this.bookings.find(
        (booking) => booking.uuid === uuid
      );

      this.$router.push({
        name: "foravila-booking-view",
        params: {
          localizator: foundBoooking.localizator,
        },
      });
    },
  },
};
</script>
