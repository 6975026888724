<template>
  <b-form id="add-accommodation-form">
    <b-row>
      <b-col>
        <h6 class="mb-1">
          Allotjament
          <b-button
            v-if="!selectedAccommodations.length"
            variant="link"
            size="sm"
            @click="selectAllAccommodations"
          >
            Seleciona'ls tots
          </b-button>
          <b-button
            v-if="selectedAccommodations.length"
            variant="link"
            class="ml-0"
            size="sm"
            @click="clearSelection"
          >
            Esborrar selecció
          </b-button>
        </h6>
        <v-select
          v-model="selectedAccommodations"
          :options="accommodationOptions"
          class="accommodation-select"
          :reduce="(option) => option.value"
          multiple
          :close-on-select="false"
          :loading="loadingAccommodations"
        />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row
      class="mt-2 mb-1 d-flex justify-content-between justify-content-sm-end"
    >
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { BForm, BRow, BCol, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      selectedAccommodations: [],
    };
  },
  computed: {
    loadingAccommodations() {
      return this.$store.getters["accommodations/loading"];
    },
    accommodations() {
      return this.$store.getters["accommodations/accommodations"];
    },
    availableAccommodations() {
      return this.$store.getters["accommodations/availableAccommodations"];
    },
    promotion() {
      return this.$store.getters["promotion/promotion"];
    },
    promotionAccommodations() {
      return this.promotion?.accommodations || [];
    },
    accommodationOptions() {
      if (this.accommodations.length === 0) return [];
      return this.accommodations.map((a) => ({
        label: a.name,
        value: a["@id"],
      }));
    },
  },
  created() {
    this.initSelectedAccommodations();
  },
  methods: {
    initSelectedAccommodations() {
      if (!this.promotionAccommodations.length) return;
      this.selectedAccommodations = this.promotionAccommodations.map(
        (accommodation) => accommodation["@id"]
      );
    },
    save() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch(`promotion/addAccommodations`, {
          promotionUuid: this.promotion.uuid,
          accommodations: this.selectedAccommodations,
        })
        .then(() => {
          notifySuccess(
            "Promoció actualitzada",
            "Els allotjaments vinculats a aquesta promoció han estat actualitzats correctament"
          );
          this.$emit("added");
        })
        .catch(() => {
          notifyError(
            "Promoció no actualitzada",
            "Hi ha hagut algun error al intentar actualitzar els allotjaments vinculats a aquesta promoció. Intenta-ho un altre cop o contacta amb en Pedro si el problema persisteix."
          );
          this.$emit("error");
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    cancel() {
      this.$emit("cancel");
    },
    selectAllAccommodations() {
      this.selectedAccommodations = this.availableAccommodations.map(
        (accommodation) => accommodation["@id"]
      );
    },
    clearSelection() {
      this.selectedAccommodations = [];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-accommodation-form {
  min-height: 450px;
  .accommodation-select {
    min-width: 175px;
  }
}
</style>
