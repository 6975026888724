<template>
  <b-card title="Allotjaments">
    <!-- NO ITEMS ALERT -->
    <b-alert
      class="mb-2"
      variant="warning"
      :show="mappedAccommodations.length === 0"
    >
      <div class="alert-body">
        No hi ha cap allotjament vinculat a aquesta promoció
      </div>
    </b-alert>

    <b-static-table
      :items="mappedAccommodations"
      :fields="fields"
      show-search
      show-button
      button-text="Vincular allotjaments"
      @item-click="onAccommodationClicked"
      @button-click="onAddAccommodationClicked"
    />

    <!-- ADD ACCOMMODATION MODAL -->
    <b-modal
      id="add-acommodation-modal"
      title="Vincula allotjaments a la promoció"
      scrollable
      hide-footer
    >
      <add-accommodation-form
        @added="onAccommodationsAdded"
        @error="onAccommodationsAddError"
        @close="closeModal"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BAlert, BModal } from "bootstrap-vue";
import BStaticTable from "@foravila-core/components/b-static-table/BStaticTable.vue";
import AddAccommodationForm from "@/views/promotions/promotion/components/AddAccommodationForm.vue";

export default {
  components: {
    BCard,
    BAlert,
    BModal,
    BStaticTable,
    AddAccommodationForm,
  },
  props: {
    accommodations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [{ key: "name", label: this.$t("Nom"), sortable: true }],
    };
  },
  computed: {
    mappedAccommodations() {
      if (!this.accommodations?.length) return [];
      return this.accommodations.map((accommodation) => ({
        uuid: accommodation.uuid,
        name: accommodation.name,
      }));
    },
  },
  methods: {
    onAccommodationClicked(uuid) {
      this.$router.push({
        name: "foravila-accommodation-view",
        params: {
          accommodationUuid: uuid,
        },
      });
    },
    onAddAccommodationClicked() {
      this.$bvModal.show("add-acommodation-modal");
    },
    onAccommodationsAdded() {
      this.closeModal();
    },
    onAccommodationsAddError() {
      this.closeModal();
    },
    closeModal() {
      this.$bvModal.hide("add-acommodation-modal");
    },
  },
};
</script>
